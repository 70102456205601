import React from "react";
import { graphql } from "gatsby";
import Layout from "src/components/structure/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PageHeading from "src/components/page-heading";
import { Flex } from "src/components/styled/sitewide";
import { MDXRenderer } from "gatsby-plugin-mdx";

const EventTemplate = ({ data }) => {
  const event = data.mdx;
  return (
    <Layout pageTitle={event.frontmatter.name}>
      <PageHeading
        title={event.frontmatter.name}
        image="schedule"
        crumbs={["TechSlice", "Event", event.frontmatter.name]}
      ></PageHeading>
      <div className="container" style={{ marginBottom: "4rem" }}>
        <Flex style={{gap: "2rem"}}>
          <div style={{ flex: 1 }}>
            <GatsbyImage
              alt="Event image"
              image={getImage(
                event.frontmatter.image.childImageSharp.gatsbyImageData
              )}
            />
            <div style={{marginTop: "1rem", marginBottom: "2rem"}}>
              <div>When: {event.frontmatter.dateLabel}</div>
              {event.frontmatter.tags?.length && (
                <div>Tagged For: {event.frontmatter.tags.join(", ")}</div>
              )}
            </div>
          </div>
          <div style={{ flex: 2 }}>
            <MDXRenderer>{event.body}</MDXRenderer>
          </div>
        </Flex>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        name
        dateLabel
        tags
        image {
          childImageSharp {
            gatsbyImageData(width: 400, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

export default EventTemplate;
